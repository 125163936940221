const constants = {
  URL_API: 'https://apis.sisreal.net.pe',
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  ACCESS_TOKEN_EXPIRES_IN: 'expires_in',
  ACCESS_TOKEN_EXPIRES_AT: 'expires_at',
  BUSINESS_ID: 'BUSINESS_ID',
  BUSINESS_USER_ID: 'BUSINESS_USER_ID',
  MUID: 'MUID',
  API_VERSION: 'v1',
  ACCOUNTS_URL_API: '//accounts.sisreal.net.pe',
};

const exportedObject = {
  constants,
};

export default exportedObject;
